import React from 'react';
import {useDropzone} from 'react-dropzone';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {Typography, styled, Stack, Box} from '@mui/material';
import {useSnackbar} from 'notistack';

const Dropzone = styled('div')({
  border: ' 2px dashed rgb(219, 219, 219)',
  padding: '1rem',
  transition: 'background-color 0.3s',
  cursor: 'pointer',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'rgb(243, 243, 243)',
  },
});

export default function NewsFeedIconDropzone({
  onDropAccepted,
  onDropRejected,
  previewSrc,
  disabled = false,
}) {
  const {enqueueSnackbar} = useSnackbar();
  const [thumbnail, setThumbnail] = React.useState();
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',
    maxSize: 5 * 1000 * 1000, // in bytes
    multiple: false,
    onDropAccepted: async files => {
      try {
        const file = files[0];

        const objectUrl = URL.createObjectURL(file);

        setThumbnail(objectUrl);

        if (onDropAccepted) {
          onDropAccepted(file);
        }
      } catch (error) {
        enqueueSnackbar('Failed to convert to svg', {
          variant: 'error',
          persist: true,
        });
      }
    },
    onDropRejected: files => {
      setThumbnail(null);
      // console.log('reject', files);
      if (onDropRejected) {
        onDropRejected(files[0].file, files[0].errors);
      }
    },
    disabled,
  });

  React.useEffect(
    () => () => {
      if (thumbnail) {
        URL.revokeObjectURL(thumbnail);
      }
    },
    [thumbnail]
  );

  return (
    <div>
      <Stack direction="row" spacing={1}>
        {(thumbnail || previewSrc) && (
          <Box
            sx={{
              border: '1px solid #ddd',
              borderRadius: '4px',
              padding: '4px',
              width: 150,
            }}
            component="img"
            src={thumbnail || previewSrc}
            alt="thumbnail"
          />
        )}
        <Dropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography sx={{mb: 0}} align="center" paragraph>
            <AddAPhotoIcon />
          </Typography>
          {isDragActive ? (
            <Typography align="center" paragraph>
              Drop the icon here ...
            </Typography>
          ) : (
            <Typography align="center" paragraph>
              Drag n Drop the icon here, or click to select.
            </Typography>
          )}
        </Dropzone>
      </Stack>
    </div>
  );
}
